<template>
  <div>
    <filters
      :add="false"
      :search="false"
      :year-chart="true"
      @filtered-items="filter"
    />
    <b-row>
      <b-col>
        <b-card>
          <h3>{{ $t('finance.income') }}</h3>
          <app-echart-bar
            v-if="chart"
            ref="gauge"
            :option-data="optionBar"
            :resizable="true"
            class="echarts"
          />
          <!-- <IEcharts

            ref="gauge"
            :option="optionBar"
            :resizable="true"
            class="echarts min-height"
          /> -->
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import AppEchartBar from '@core/components/charts/echart/AppEchartBar.vue'
import { BCard, BRow, BCol } from 'bootstrap-vue'
import { mapActions, mapGetters } from 'vuex'
import filters from '@/components/filters.vue'

export default {
  name: 'CashFlow',
  components: {
    BRow,
    BCol,
    BCard,
    AppEchartBar,
    filters,
  },
  data() {
    return {
      chart: true,
      params: {
        year: null,
      },
      optionBar: {
        xAxis: {
          type: 'category',
          data: [
            'Январь',
            'Февраль',
            'Март',
            'Апрель',
            'Май',
            'Июнь',
            'Июль',
            'Август',
            'Сентябрь',
            'Октябрь',
            'Ноябрь',
            'Декабрь',
          ],
        },
        yAxis: {
          type: 'value',
        },
        series: [],
      },
    }
  },

  computed: {
    ...mapGetters('finance', ['GET_INCOME_STATISTICS_LIST']),
  },
  watch: {
    '$i18n.locale': {
      handler() {
        setTimeout(() => {
          this.refresh()
        }, 500)
      },
    },
  },
  mounted() {
    this.params.year = new Date().getFullYear()
    this.refresh()
  },
  methods: {
    ...mapActions('finance', ['FETCH_INCOME_STATISTICS_LIST']),

    filter(val) {
      const date = new Date(val.year).toISOString().split('-')[0]
      if (!val.year) {
        this.params.year = new Date().getFullYear()
      } else {
        this.params.year = date
      }
      this.refresh()
    },

    refresh() {
      this.chart = false
      const data = this.$_removeObjectConnection(this.optionBar)
      this.FETCH_INCOME_STATISTICS_LIST(this.params)
        .then(r => {
          data.xAxis = {
            type: 'category',
            data: r.labels.map(e => this.$t(`finance.${e}`)),
          }
          data.series = [r.series]
          this.chart = true
        })
      this.optionBar = data
    },
  },
}
</script>

<style>
  .echarts {
  width: 100%;
  min-height: 700px;
}

.hr__style {
  border-bottom: 2px solid #ebe9f1;
  margin-bottom: 35px
}
</style>
