<template>
  <div>
    <Income />
  </div>
</template>

<script>
import Income from './components/charts/Income.vue'

export default {
  name: 'Index',
  components: {
    Income,
  },
}
</script>

<style lang="scss">
.list-group-item span:nth-child(2) {
  font-size: 16px;
  font-weight: 400;
}
</style>
